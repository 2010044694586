<template>
  <div v-if="show_error">
    <vs-alert color="danger" title="No data" active="true">
      Please check your internet connection or something went wrong
    </vs-alert>
  </div>
  <div v-else id="invoice-page" style="max-width: 930px; margin: auto">

    <div class="flex flex-wrap items-center justify-between">
      <div class="mb-base mr-3">
        <vx-input-group>
          <vs-input v-model="email" v-validate="'required|email'" name="email" type="email" placeholder="Email" /><template slot="append">
          <div class="append-text btn-addon">
            <vs-button @click="sendInvoice" type="border" class="whitespace-no-wrap">Send Invoice</vs-button>
          </div>
        </template>
        </vx-input-group>
        <span v-show="errors.has('email')" class="text-danger">{{ errors.first('email') }}</span>

      </div>


      <div class="flex items-center">
        <vs-button class="mb-base mr-3" type="border" icon-pack="feather" @click="downloadPdf(order_id)" icon="icon icon-download">Download</vs-button>
        <vs-button class="mb-base mr-3" icon-pack="feather" icon="icon icon-file" @click="printInvoice">Print</vs-button>
      </div>
    </div>

    <vx-card id="invoice-container">

      <!-- INVOICE METADATA -->
      <div class="vx-row leading-loose p-base">
        <div class="vx-col w-full mb-5">
          <table style="width: 100%">
            <tr>
              <td style="width: 50%">
                <p>www.himu.com.bd</p>
              </td>
              <td class="text-right" style="width: 50%">
                <img class="float-right" height="60px" src="@/assets/images/logo/Round_logo.png" alt="vuexy-logo">
              </td>
            </tr>
          </table>
        </div>
        <div class="vx-col w-full">
          <table class="table table-bordered" style="width: 100%">
            <tr>
              <td style="width: 50%">
                <h5>Recipient</h5>
                <div class="invoice__recipient-info my-4">
                  <p>{{ order.shipping_first_name }}</p>
                  <p>{{ order.shipping_address_1 }}</p>
                </div>
                <div class="invoice__recipient-contact ">
                  <p v-if="order.customer_email" class="flex items-center">
                    <feather-icon icon="MailIcon" svgClasses="h-4 w-4"></feather-icon>
                    <span class="ml-2">{{ order.customer_email }}</span>
                  </p>
                  <p class="flex items-center">
                    <feather-icon icon="PhoneIcon" svgClasses="h-4 w-4"></feather-icon>
                    <span class="ml-2">{{ order.customer_phone }}</span>
                  </p>
                </div>
              </td>
              <td class="text-right" style="width: 50%">
                <h1>Invoice</h1>
                <div class="invoice__invoice-detail mt-6">

                  <p> <span class="text-dark font-semibold">INVOICE NO.</span> {{ order.id }}</p>
                  <!--<p>{{ invoiceDetails.invoiceNo }}</p>-->

                  <h6 class="mt-4">INVOICE DATE</h6>
                  <p>{{ order.created_at | date(true) }}</p>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <!-- INVOICE CONTENT -->
      <div class="p-base">
        <table style="width:100%" class="border-collapse">
          <tr>
            <th class="p-2 border border-solid d-theme-border-grey-light">SL#</th>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center">product</th>
            <th class="p-2 border border-solid d-theme-border-grey-light">Qty</th>
            <th class="p-2 border border-solid d-theme-border-grey-light">Subtotal</th>
          </tr>
          <tr v-for="(tr, index) in order.products" :key="index">
            <th class="p-2 border border-solid d-theme-border-grey-light">{{index+1}}</th>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center">{{ tr.product.name }}</th>
            <th class="p-2 border border-solid d-theme-border-grey-light">{{tr.qty}}</th>
            <th class="p-2 border border-solid d-theme-border-grey-light">{{tr.line_total.amount | currency}}</th>
          </tr>
        </table>
        <table style="width:100%" class="border-collapse w-1/2 ml-auto mt-4">
          <tr>
            <th class="p-2 border border-solid d-theme-border-grey-light">Sub Total</th>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center">{{ order.sub_total.amount | currency }}</th>
          </tr>
          <tr v-if="order.discount.amount > 0">
            <th class="p-2 border border-solid d-theme-border-grey-light">Discount</th>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center">{{ order.discount.amount | currency }}</th>
          </tr>
          <tr>
            <th class="p-2 border border-solid d-theme-border-grey-light">Total</th>
            <th class="p-2 border border-solid d-theme-border-grey-light text-center">{{ order.total.amount | currency }}</th>
          </tr>
        </table>
      </div>

      <!-- INVOICE FOOTER -->
      <div class="invoice__footer text-right p-base">
        <div class="vx-col w-full">
          <table style="width: 100%">
            <tr>
              <td style="width: 50%">

              </td>
              <td class="text-right" style="width: 50%">
                <h3>himu.com.bd</h3>
                <p>info@himu.com.bd</p>
                <p>Zoo Road,Mirpur 1,Dhaka</p>
                <p>01718333057</p>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
  export default{
    data() {
      return {
        order_id:this.$route.params.id,
        order:{},
        show_error:false,
        email:'',
      }
    },
    computed: {

    },
    methods: {
      getOrder(){
        this.$vs.loading();
        this.axios.get('order/'+this.order_id).then(({data})=>{
          this.$vs.loading.close();
          this.order = data;
        }).catch(error => {
          this.show_error = true,
          this.$vs.loading.close();
          this.$vs.notify({
            tile:'Error',
            text: error.message,
            color:'danger',
          })
        })
      },
      printInvoice() {
        window.print()
      },
      sendInvoice(){
        this.$validator.validateAll().then(result => {
            if (result) {
              this.$vs.loading();
              this.axios.post('/order/'+this.order_id+'/email', {email:this.email}).then(res=>{
                this.$vs.loading.close();
                if (res.data.status == 'success'){
                  this.email = '';
                  this.$validator.reset();
                }
                this.$vs.notify({
                  title:res.data.status,
                  text:res.data.message,
                  color:res.data.status == 'success'?'success':'error'
                })
              }).catch(error => {
                this.$vs.loading.close();
                this.$vs.notify({
                  text:error.message,
                  title:'Attempt failed',
                  color:'warning',
                })
              })
            }else {
              this.$vs.notify({
                text:'Please enter valid email',
                title:'Invalid Info',
                color:'danger',
              })
            }

        })
      },

      downloadPdf(id){
        this.$vs.loading();
        this.axios.get('/order/'+id+'/pdf', {
          responseType: 'blob',
        }).then(response => {
          this.$vs.loading.close();
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          console.log(fileURL);

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'invoice.pdf');
          document.body.appendChild(fileLink);

          fileLink.click();
        }).catch(error =>{
          this.$vs.loading.close();
          this.$vs.notify({
            text:error.message,
            title:'Attempt failed',
            color:'warning',
          })
        });
      }
    },
    components: {},
    mounted() {
      this.getOrder();
      this.$emit("setAppClasses", "invoice-page")
    }
  }
</script>

<style lang="scss">
  .table-bordered {
    table, th, td {
      border: 2px solid #f8f8f8;
    }
  }
  table {
    border-collapse: collapse;
  }
  th, td {
    padding: 10px;
  }
  @media print {
    table {
      border: collapsed;
    }
    .invoice-page {
      * {
        visibility: hidden;
      }

      #content-area {
        margin: 0 !important;
      }

      #invoice-container,
      #invoice-container * {
        visibility: visible;
      }
      #invoice-container {
        position: absolute;
        left: 0;
        top: 0;
        box-shadow: none;
      }
    }
  }
</style>
